import React, {useState, useEffect, useCallback, useContext, useRef, createRef, LegacyRef, RefObject} from "react"
import {endpoint} from "../../variables"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {getUrlParams} from "../../utils/_helpers"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {handleError, isEmpty} from "../../shared/helpers"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {CustomerIF, DataResponseIF, MetaIF, ProductIF, WorksheetIF} from "../../../types"
import {useTranslation} from "react-i18next"
import {Axios} from "../../Axios"
import {getCustomer} from "../../utils/Getters/getCustomer"
import {worksheetPath} from "../Worksheet/Worksheet"
import {handleDeleteItem} from "../../shared/_item_helper"
import {Context} from "../../contexts/Context"
import "./WorksheetsPerCustomer.sass"
import {AuthContext} from "../../contexts/Auth"

export const worksheetsPath = "/worksheets/"
export const worksheetsPerCustomer = "worksheets-per-customer"

interface WorksheetsIF {
    compact?: boolean
}

interface CheckboxIF {
    ref: LegacyRef<HTMLInputElement> | undefined
    checked: boolean | number
}

interface SelectedItemIF {
    worksheetId: number
    productId: number
    quantity: number
}

const WorksheetsPerCustomer = ({compact}: WorksheetsIF) => {
    const [worksheets, setWorksheets] = useState<Array<WorksheetIF> | null>(null)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "customerId", "worksheetPage"])
    const page = parseInt(path["pageNumber"]) || 1
    const customerId = parseInt(path["customerId"])
    const worksheetPage = parseInt(path["worksheetPage"])
    const [loader, setLoader] = useState(true)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [customer, setCustomer] = useState<CustomerIF | null>(null)
    const extraPath = `${customerId}/`
    const {confirmation} = useContext(Context)
    const [checkAll, setCheckAll] = useState(false)
    const tableBodyRef = useRef<HTMLTableSectionElement>(null)
    const [checkBoxes, setCheckBoxes] = useState<Array<CheckboxIF> | null>(null)
    const [lastInvoiceId, setLastInvoiceId] = useState<number>(0)
    const invoicePath = "/invoice/"
    const [showInvoiceDropdown, setShowInvoiceDropdown] = useState(false)
    const [customerInvoices, setCustomerInvoices] = useState<Array<any>>()
    const {rootState: {theUser}} = useContext(AuthContext)

    const setRefs = (data: Array<ProductIF>) => {
        const checkboxSet: Array<any> = []

        data.forEach((_item, index) => {
            checkboxSet[index] = {
                ref: createRef(),
                checked: false
            }
        })

        setCheckBoxes(checkboxSet)
    }

    const fetchData = useCallback(() => {
        getCustomer({
            customerNumber: customerId,
            callback: (data: CustomerIF) => setCustomer(data)
        })

        Axios.get(`${endpoint}${worksheetsPath}customer/${extraPath}${worksheetPage}`)
            .then(({data}) => {
                setMeta(data.meta)
                setWorksheets(data.resource)
                setUnfilteredData(data)
                setRefs(data.resource)
            })
            .catch(error => handleError(error))
            .finally(() => {
                setLoader(false)
            })
    }, [customerId, extraPath, worksheetPage])

    const fetchInvoiceData = () => {
        setLoader(true)

        Axios.get(`${endpoint}${invoicePath}all/${customerId}`)
            .then(({data}) => {
                setCustomerInvoices(data.resource)
            })
            .catch(error => handleError(error))
            .finally(() => {
                setLoader(false)
            })

        Axios.get(`${endpoint}${invoicePath}last/`)
            .then(({data}) => {
                setLastInvoiceId(parseInt(data.resource) + 1)
            })
            .catch(error => handleError(error))
    }
    useEffect(() => {
        fetchInvoiceData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleViewClick = (id: number) => {
        navigate(`${worksheetPath}${page}/${customerId}/${worksheetPage}/${id}/`)
    }

    const handleDelete = (event: Event, id: number) => {
        handleDeleteItem({
                event: event as unknown as Event,
                confirmation,
                id,
                list: worksheets,
                setList: setWorksheets,
                callback: fetchData,
                navigate,
                resource: "worksheet"
            }
        )
    }

    const handleCheckBoxChange = (index: number | string) => {
        if (!checkBoxes) {
            return
        }

        setCheckBoxes(checkBoxes.map((item, currentIndex: number) => {
            return {
                ref: item.ref,
                checked: currentIndex === index ? !item.checked : item.checked
            }
        }))
    }

    const handleCheckAll = () => {
        if (!checkBoxes) {
            return
        }

        setCheckAll(!checkAll)

        setCheckBoxes(checkBoxes.map((item) => {
            return {
                ref: item.ref,
                checked: !checkAll
            }
        }))
    }

    const showTable = () => {
        let itemsList = []

        if (!worksheets || !checkBoxes) {
            return null
        }

        itemsList.push(Object.values(compact ? worksheets.slice(0, 5) : worksheets).map((item: any, index) => {
            const {id, name, price, discountPrice, quantity, invoice} = item

            return (
                <tr className={`row${invoice ? " disabled" : null}`} key={index}>
                    <td onClick={() => invoice ? null : handleViewClick(id)} className="name">
                        {name}
                    </td>
                    <td onClick={() => invoice ? null : handleViewClick(id)} className="items">
                        {Number(price).toFixed(2)}
                    </td>
                    {theUser.discount ? <td onClick={() => handleViewClick(id)} className="items">{Number(discountPrice).toFixed(2)}</td> : ""}
                    <td onClick={() => invoice ? null : handleViewClick(id)} className="items">
                        {quantity}
                    </td>
                    <td className="actions">
                        {invoice ?
                            <Link to={`${invoicePath}1/${invoice}`}>
                                {customerInvoices?.filter(item => item.id === invoice)[0]?.number}
                            </Link>
                            :
                            <input type="checkbox"
                                   ref={checkBoxes[index].ref}
                                   value={id}
                                   onChange={() => handleCheckBoxChange(index)}
                                   checked={!!checkBoxes[index].checked}
                            />
                        }

                        <Actions compact={compact}
                                 id={id}
                                 {...invoice ? {} : {viewCallback: handleViewClick}}
                                 deleteCallback={handleDelete}
                        />
                    </td>
                </tr>
            )
        }))

        return itemsList
    }

    const showList = () => {
        if (isEmpty(worksheets)) {
            return <NoEntries/>
        }

        return (
            <table className={`general-table worksheet-table${compact ? " compact" : ""}`}>
                <thead>
                <tr>
                    <th className="name">{t("worksheets.form.product")}</th>
                    <th className="items">{t("worksheets.form.price")}</th>
                    {theUser.discount ? <th className="items">{t("worksheets.form.discountPrice")}</th> : ""}
                    <th className="items">{t("worksheets.form.quantity")}</th>
                    <th className="actions">
                        {worksheets?.map(item => !Boolean(item.invoice)).filter(Boolean).length ?
                            <input type="checkbox" checked={checkAll} onChange={handleCheckAll}/>
                            :
                            null
                        }
                    </th>
                </tr>
                </thead>
                <tbody ref={tableBodyRef}>
                {showTable()}
                </tbody>
            </table>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setWorksheets(data ? data.resource as Array<ProductIF> : unfilteredData.resource as Array<ProductIF>)
    }

    const resetData = () => {
        fetchData()
        fetchInvoiceData()
    }

    const addToInvoice = (id?: number) => {
        const selectedItems: Array<SelectedItemIF> = []

        setLoader(true)

        checkBoxes?.forEach(item => {
            const input = item.ref as RefObject<HTMLInputElement>

            if (!input || !input.current || !input.current.checked || !input.current.value) {
                return
            }

            const value = parseInt(input.current.value)
            const worksheet = worksheets?.find(item => item.id === value)

            selectedItems.push({
                worksheetId: value,
                productId: worksheet?.product_id || 0,
                quantity: worksheet?.quantity || 0
            })
        })

        const params = {
            invoiceId: id || lastInvoiceId,
            customerId,
            items: selectedItems
        }

        if (id) {
            Axios.put(`${endpoint}${invoicePath}${id}/`, params)
                .catch(error => handleError(error))
                .finally(() => {
                    resetData()
                })
        } else {
            Axios.post(`${endpoint}${invoicePath}`, params)
                .catch(error => handleError(error))
                .finally(() => {
                    resetData()
                })
        }
    }

    const invoiceDropdown = () => {
        return (
            <div className="invoice-dropdown">
                <ul>
                    <li onClick={() => addToInvoice()}>{t("worksheets.newInvoice")} {lastInvoiceId}</li>
                    {customerInvoices && customerInvoices.map((item, index) => {
                        return (
                            <li key={index} onClick={() => addToInvoice(item.id)}>{item.number}</li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const buttons = [
        {
            text: "button.search"
        },
        {
            text: "button.add",
            onClick: () => navigate(`${worksheetPath}${page}/${extraPath}${worksheetPage}/0/`)
        },
        ...(checkBoxes && checkBoxes.some(item => item.checked) ? [{
            text: "button.toInvoice",
            onClick: () => setShowInvoiceDropdown(!showInvoiceDropdown),
            Dropdown: showInvoiceDropdown ? invoiceDropdown : null
        }] : []),
        {
            text: "button.list",
            classname: "secondary",
            onClick: () => navigate(`${worksheetsPath}${page}/`)
        }
    ]

    return (
        <DefaultList list={worksheetsPerCustomer}
                     title={customer?.name}
                     buttons={buttons}
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={worksheetPage}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default WorksheetsPerCustomer
