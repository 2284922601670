import React, {useState, useEffect, useContext, useCallback} from "react"
import "./Products.sass"
import {useLocation, useNavigate} from "react-router-dom"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {isEmpty} from "../../shared/helpers"
import {productPath} from "../Product/Product"
import {getProducts} from "../../utils/Getters/getProducts"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {DataResponseIF, MetaIF, ProductIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"
import {useTranslation} from "react-i18next"

export const productsPath = "/products/"

interface ProductsIF {
    compact?: boolean
}

const Products = ({compact}: ProductsIF) => {
    const [products, setProducts] = useState<Array<ProductIF> | null>(null)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const page = parseInt(path["pageNumber"]) || 1
    const {confirmation} = useContext(Context)
    const [loader, setLoader] = useState(true)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const [firstRender, setFirstRender] = useState(true)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {rootState: {isAuth, theUser}} = useContext(AuthContext)

    const fetchProducts = useCallback(() => {
        getProducts({
            callback: (data: any) => {
                setLoader(false)
                setMeta(data.meta)
                setProducts(data.resource)
                setUnfilteredData(data)
            },
            page
        })
    }, [page])

    useEffect(() => {
        if (!firstRender) {
            fetchProducts()
        }
    }, [fetchProducts, firstRender])

    useEffect(() => {
        setFirstRender(false)
    }, [page])

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchProducts()
    }, [isAuth, fetchProducts])

    const handleDelete = (event: Event, id: number, list: Array<ProductIF>) => {
        handleDeleteItem({
                event: event as unknown as Event,
                confirmation,
                id,
                list,
                setList: setProducts,
                navigate,
                resource: "product"
            }
        )
    }

    const handleViewClick = (id: number) => {
        navigate(`${productPath}${page}/${id}/`)
    }

    const showTable = () => {
        let productsList = []

        if (!products) {
            return ""
        }

        productsList.push(Object.values(compact ? products.slice(0, 5) : products).map((product: any, index) => {
            const {id, code, name, price, discountPrice, unit} = product

            return (
                <tr className="row" key={index}>
                    <td onClick={() => handleViewClick(id)} className="id">{code}</td>
                    <td onClick={() => handleViewClick(id)} className="name">{name}</td>
                    <td onClick={() => handleViewClick(id)} className="items">{Number(price).toFixed(2)}</td>
                    {theUser && theUser.discount ? <td onClick={() => handleViewClick(id)} className="items">{Number(discountPrice).toFixed(2)}</td> : ""}
                    <td onClick={() => handleViewClick(id)} className="items">{unit}</td>
                    {!compact && <td className="actions">
                        <Actions compact={compact}
                                 id={id}
                                 viewCallback={handleViewClick}
                                 deleteCallback={handleDelete}
                        />
                    </td>}
                </tr>
        )
        }))

        return productsList
    }

    const showList = () => {
        if (isEmpty(products)) {
            return <NoEntries/>
        }

        return (
            <table className={`general-table products-table${compact ? " compact" : ""}`}>
                <thead>
                <tr>
                    <th className="id">#</th>
                    <th className="name">{t("products.form.name")}</th>
                    <th className="items">{t("products.form.price")}</th>
                    {theUser && theUser.discount ? <th className="items">{t("products.form.discountPrice")}</th> : ""}
                    <th className="items">{t("products.form.unit")}</th>
                    {!compact && <th className="actions"/>}
                </tr>
                </thead>
                <tbody>
                {showTable()}
                </tbody>
            </table>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setProducts(data ? data.resource as Array<ProductIF> : unfilteredData.resource as Array<ProductIF>)
    }

    return (
        <DefaultList list="products"
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default Products
